import cn from 'classnames'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import TableLoadingSkeleton from './TableLoadingSkeleton'
import { HiOutlineViewColumns } from 'react-icons/hi2'

import {
  TextInput,
  ErrorBoundaryContainer,
  Pagination,
  Table,
  Entries,
  Title,
  Categories,
  Panel,
  Tiles,
  ConditionalRender,
  If,
  Button,
  DataTableService,
  coreThemes,
  Action,
  ON_ENTER,
  ON_TYPE,
  PageSizeOptions,
  CheckboxList,
  DropdownPopper
} from 'simple-core-ui'

import s from './DataTable.scss'

const DataTable = ({
  title,
  primaryAction,
  secondaryAction,
  pageSizeOptions,
  ordering,
  search,
  searchLabel = 'Search',
  previousPage,
  page,
  nextPage,
  pageSize,
  totalEntries,
  filteredTotal,
  categories,
  category,
  categoriesClearable,
  isLoading,
  alwaysShowLoadingSkeleton,
  isError,
  updatePageSize,
  updateSearch,
  updateCurrentPage,
  striped,
  updateSortOrder,
  updateFilterCategory,
  noHover,
  panelTitle,
  panelActions,
  subtitle,
  panelStyles,
  entryLanguage = {
    singular: 'entry',
    plural: 'entries'
  },
  hasActionsHeader = true,
  hasSearch = ON_TYPE,
  categoriesClassName,
  className,
  statusText = 'No results found.',
  customStatusText,
  actionButtons,
  filters,
  customSearchOptions,
  bulkActions = [],
  fixedHeader = false,
  tableHeight,
  shouldShowPageSizeOptions = true,
  selectedRowsLength,
  revealBulkButton = false,
  hasPagination = true,
  hasInfinityScroll = false,
  pageSizeEntriesWrapperClass,
  customizableColumns = null,
  columns,
  footer,
  ...tableProps
}) => {
  return (
    <ErrorBoundaryContainer isError={isError}>
      {(isError, message) => {
        const shouldShowCategories = categories.length > 1
        const shouldShowTiles = categories.filter(({ isTile }) => isTile).length > 1
        const shouldShowHeader = title || categories.length || primaryAction || secondaryAction
        const pageWindow = DataTableService.getPageWindow(5, pageSize, filteredTotal, page)
        // currently getPageBoundaries is zero indexed but returns the correct last page

        const { firstPage, lastPage } = DataTableService.getPageBoundaries(
          pageSize,
          filteredTotal || totalEntries
        )

        const getPlaceholder = () => {
          if (get(customSearchOptions, 'placeholder')) {
            return customSearchOptions.placeholder
          } else if (hasSearch === ON_ENTER) {
            return 'Press enter to search'
          } else if (hasSearch === ON_TYPE) {
            return 'Start typing to search'
          } else {
            return null
          }
        }

        const getCategoryContent = ({ label, value, count, customContent, ...props }) => {
          const getAdjustedLabel = () =>
            shouldShowTiles ? label : `${label} ${count > 0 ? `(${count})` : ''}`.trim()
          const { isActive = isEqual(value, category) } = props
          return {
            content: customContent ? label : getAdjustedLabel(),
            id: value,
            isActive,
            customContent
          }
        }

        const TableButtonGroup = () => {
          return (
            <If condition={primaryAction || secondaryAction || actionButtons}>
              <section className={s.tableButtons}>
                <If condition={secondaryAction}>
                  {() => <Button isSecondary {...secondaryAction} theme={coreThemes.EB} />}
                </If>
                <If condition={primaryAction}>
                  {() => <Button isPrimary {...primaryAction} theme={coreThemes.EB} />}
                </If>
                <If condition={actionButtons}>{actionButtons}</If>
              </section>
            </If>
          )
        }

        const renderedDataTable = (
          <section className={cn(s.container, className)}>
            <If condition={shouldShowHeader}>
              <header className={s.header}>
                <Title text={title} rank={2} />
                <TableButtonGroup />
              </header>

              {shouldShowCategories && (
                <div className={cn('filter-container', categoriesClassName)}>
                  <Categories
                    categories={categories.map(category => getCategoryContent(category))}
                    clickCb={updateFilterCategory}
                  />
                </div>
              )}
            </If>
            <main>
              <If condition={hasActionsHeader && !hasInfinityScroll}>
                <section className={`${s.filtersWrapper} ${filters ? '' : s.noFilters}`}>
                  {filters && <div className={s.filters}>{filters}</div>}
                  <section
                    className={`${s.configOptions} ${shouldShowCategories ? s.hideBorder : ''}`}
                  >
                    <section className={cn(s.pageSizeEntriesWrapper, pageSizeEntriesWrapperClass)}>
                      {(revealBulkButton ||
                        (tableProps.selectedRows && tableProps.selectedRows.size > 0) ||
                        selectedRowsLength > 0) && (
                        <section className={s.bulkActionsWrapper}>
                          {Array.isArray(bulkActions)
                            ? bulkActions.map((action, i) => <Action key={i} action={action} />)
                            : bulkActions}
                        </section>
                      )}
                      <Entries
                        page={page}
                        pageSize={pageSize}
                        total={totalEntries}
                        filtered={filteredTotal}
                        language={entryLanguage}
                      />
                      {!shouldShowPageSizeOptions ? null : (
                        <PageSizeOptions
                          options={pageSizeOptions}
                          pageSize={pageSize}
                          onChange={updatePageSize}
                        />
                      )}
                    </section>
                    <div style={{ display: 'flex' }}>
                      {customizableColumns && (
                        <section className={s.customizeColumns}>
                          <DropdownPopper
                            value={[]}
                            placeholder={
                              <span>
                                <HiOutlineViewColumns
                                  style={{ fontSize: 20, position: 'relative', top: 4 }}
                                />{' '}
                                Manage Columns
                              </span>
                            }
                            pillClass={s.dropdownPoppperPill}
                            noActiveStyles
                          >
                            <CheckboxList
                              value={customizableColumns.visibleColumns ?? columns}
                              onConfirm={customizableColumns.changeSelectedColumns}
                              sortSelection={customizableColumns.sortSelection}
                              options={customizableColumns.allColumns}
                              serialiser={data => data}
                              secondaryAction={{
                                element: (
                                  <a
                                    style={{
                                      textDecoration: 'underline',
                                      marginLeft: 10,
                                      cursor: 'pointer'
                                    }}
                                  >
                                    Restore Default
                                  </a>
                                ),
                                onClick: customizableColumns.restoreDefaultColumns
                              }}
                              searchPlaceholder="Search column names"
                            />
                          </DropdownPopper>
                        </section>
                      )}
                      {hasSearch && (
                        <div
                          className={cn(s.searchContainer, customSearchOptions?.containerClassName)}
                        >
                          {!customSearchOptions?.hideLabel && <>{searchLabel}: </>}
                          <TextInput
                            value={search}
                            placeholder={getPlaceholder()}
                            onChange={hasSearch === ON_TYPE && updateSearch}
                            onEnter={hasSearch === ON_ENTER && updateSearch}
                            style={
                              get(customSearchOptions, 'styles')
                                ? customSearchOptions.styles
                                : { width: 220 }
                            }
                            isLoading={!isError && Boolean(isLoading)}
                            testid="search_input"
                            debounceDelay={500}
                          />
                          {customSearchOptions?.icon}
                        </div>
                      )}
                    </div>
                  </section>
                </section>
              </If>
              <ConditionalRender
                conditions={[
                  {
                    condition: isError,
                    content: message
                  },
                  {
                    condition:
                      isLoading &&
                      ((search?.trim().length === 0 && tableProps.rows.length === 0) ||
                        alwaysShowLoadingSkeleton),
                    content: <TableLoadingSkeleton columnCount={columns?.length} />
                  },
                  {
                    condition: search?.trim().length !== 0 && tableProps.rows.length === 0,
                    content: (
                      <p className={s.statusText}>
                        {filters ? `${statusText} Try adjusting the filters.` : 'No results found.'}
                      </p>
                    )
                  },
                  {
                    condition: tableProps.rows.length === 0,
                    content: (
                      <p className={s.statusText}>
                        {customStatusText
                          ? customStatusText
                          : filters
                          ? `${statusText} Try adjusting the filters.`
                          : statusText}
                      </p>
                    )
                  }
                ]}
                fallback={
                  <>
                    <Table
                      {...tableProps}
                      columns={columns}
                      striped={striped}
                      noHover={noHover}
                      sortTable={updateSortOrder}
                      fixedHeader={fixedHeader}
                      tableHeight={tableHeight}
                      currentPage={page}
                      lastPage={lastPage}
                      updateCurrentPage={updateCurrentPage}
                      hasInfinityScroll={hasInfinityScroll}
                    />
                    {hasPagination && !hasInfinityScroll && (
                      <footer className={s.footer}>
                        <Pagination
                          previousPage={previousPage}
                          currentPage={page}
                          nextPage={nextPage}
                          updateCurrentPage={updateCurrentPage}
                          pageWindow={pageWindow}
                          firstPage={firstPage + 1}
                          lastPage={lastPage}
                        />
                      </footer>
                    )}
                  </>
                }
              />
            </main>
          </section>
        )

        const withTiles = shouldShowTiles ? (
          <Tiles
            clickCb={newCategory => {
              category === newCategory
                ? updateFilterCategory(null)
                : updateFilterCategory(newCategory)
            }}
            groups={categories
              .filter(({ isTile }) => isTile)
              .map(({ label, value, count }) => ({
                id: value,
                count,
                text: label,
                isActive: isEqual(value, category)
              }))}
            clearable={categoriesClearable}
          />
        ) : null

        return (
          <section>
            {withTiles}
            <Panel
              title={panelTitle}
              subtitle={subtitle}
              isBodyOnly={!panelTitle}
              actions={panelActions}
              styles={panelStyles}
              panelStyles={panelStyles}
              hasInnerPadding={tableProps.hasInnerPadding}
              footer={footer}
            >
              {renderedDataTable}
            </Panel>
          </section>
        )
      }}
    </ErrorBoundaryContainer>
  )
}

export default DataTable
